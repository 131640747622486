<template>
  <div>
    <query-form labelWidth="110px" class="content_block" ref="queryForm" @search="handleSearch" @reset="handleReset">
      <el-form-item label="认证状态：">
        <el-select v-model="query.authenticationStatus" clearable @change="handleChange">
          <el-option v-for="i in AuthStatusList" :key="i.value" :label="i.name" :value="i.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="名称：">
        <el-input placeholder="请输入名称" v-model.trim="query.name" clearable></el-input>
      </el-form-item>
      <el-form-item label="门户类型：">
        <el-select v-model="query.portalType" clearable @change="handleChange">
          <el-option v-for="i in PortalTypeList" :key="i.value" :label="i.name" :value="i.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提交人：">
        <el-input placeholder="请输入提交人" v-model.trim="query.authenticationSubmitName" clearable></el-input>
      </el-form-item>
      <el-form-item label="提交人手机号：">
        <el-input placeholder="请输入提交人手机号" v-model.trim="query.authenticationSubmitPhone" clearable></el-input>
      </el-form-item>
      <el-form-item label="编号：">
        <el-input placeholder="请输入编号" v-model.trim="query.code" clearable></el-input>
      </el-form-item>
      <el-form-item label="审核状态：">
        <el-select v-model="query.authenticationAuditStatus" clearable @change="handleChange">
          <el-option v-for="i in AuditStatusList" :key="i.value" :label="i.name" :value="i.value"></el-option>
        </el-select>
      </el-form-item>
    </query-form>
    <table-with-pagination
      :tableData="tenantList"
      class="content_block"
      :columnList="columnList"
      :pagination.sync="pagination"
      @size-change="getTableList"
      @current-change="getTableList"
    />
  </div>
</template>
<script>
import { getAuthenticationPage } from '@/api/tenant';
import TableWithPagination, { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { dateFormat } from '@/utils/dateFormat';
import { PortalTypeList } from '@/model/xzpt/portalTypes';
import authStatusEnum, { AuthStatusList } from '@/model/xzpt/auth';
import auditStatusEnum, { AuditStatusList } from '@/model/xzpt/audit';

export default {
  name: 'USERAUTH_INDEX',
  mixins: [TablePaginationMixin],
  components: {
    TableWithPagination,
  },
  data () {
    return {
      query: {
        portalType: '',
        name: '',
        authenticationStatus: '',
        authenticationSubmitName: '',
        authenticationSubmitPhone: '',
        code: '',
        authenticationAuditStatus: '',
      },
      PortalTypeList,
      AuthStatusList,
      AuditStatusList,
      tenantList: [],
      columnList: [
        {
          tooltip: true,
          label: '门户类型',
          minWidth: '100px',
          prop: 'portalTypeDesc',
        },
        {
          tooltip: true,
          label: '名称',
          minWidth: '150px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '编号',
          minWidth: '150px',
          prop: 'code',
        },
        {
          tooltip: true,
          label: '提交人',
          minWidth: '150px',
          prop: 'authenticationSubmitName',
        },
        {
          tooltip: true,
          label: '提交人手机号',
          minWidth: '150px',
          prop: 'authenticationSubmitPhone',
        },
        {
          tooltip: true,
          label: '提交人邮箱',
          minWidth: '150px',
          prop: 'authenticationSubmitEmail',
        },
        {
          tooltip: true,
          label: '认证提交时间',
          minWidth: '150px',
          prop: 'authenticationTime',
          sortable: true,
          format: v => {
            return v ? dateFormat(v).format('YYYY-MM-DD HH:mm:ss') : '--';
          },
        },
        {
          tooltip: true,
          label: '认证状态',
          minWidth: '150px',
          prop: 'authenticationStatus',
          format: v => {
            return authStatusEnum[v];
          },
        },
        {
          tooltip: true,
          label: '审核状态',
          minWidth: '150px',
          prop: 'authenticationAuditStatus',
          format: v => {
            return auditStatusEnum[v];
          },
        },
        {
          label: '操作',
          minWidth: '200px',
          fixed: 'right',
          render: (h, { row }) => {
            let actions = [];

            if (row.authenticationAuditStatus === '1') {
              actions.push(
                <span
                  class="cursor-pointer text-primary margin-left"
                  onClick={this.handleActionClick.bind(this, row, 'audit')}
                >
                  审核
                </span>,
              );
            }

            actions.push(
              <span
                class="cursor-pointer text-primary margin-left"
                onClick={this.handleActionClick.bind(this, row, 'check')}
              >
                查看
              </span>,
            );
            return <div>{...actions}</div>;
          },
        },
      ],
    };
  },
  methods: {
    handleActionClick ({ id, portalType, ownerUserId }, action) {
      this.$router.push({
        name: 'XZ_Certification_Detail',
        query: {
          id,
          portalType,
          auditFlag: action === 'audit' ? 'audit' : '',
          ownerUserId: portalType === '3' ? ownerUserId : null,
        },
      });
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      this.query = {
        name: '',
        phoneNumber: '',
        comparisonOperator: '',
        tenantNum: '',
        registerSource: '',
        registerType: '',
        startTime: '',
        endTime: '',
      };
      this.handleSearch();
    },
    getTableList () {
      const query = {};

      for (let k in this.query) {
        if (this.query[k]) {
          query[k] = this.query[k];
        }
      }

      let param = {
        ...query,
        orderBy: [{
          orderByProperty: 'authenticationTime',
          orderByType: 'DESC',
        }],
        pageSize: this.pagination.pageSize,
        pageNum: this.pagination.currentPage,
      };

      getAuthenticationPage(param).then(res => {
        this.tenantList = res.body.list;
        this.pagination.total = res.body.total;
      });
    },
    handleChange () {
      this.pagination.currentPage = 1;

      this.getTableList();
    },
  },
};
</script>
