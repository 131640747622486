import Enum from '@/utils/Enum';

const auditStatusList = [
  {
    value: 1,
    name: '审核中',
  },
  {
    value: 2,
    name: '审核成功',
  },
  {
    value: 3,
    name: '审核失败',
  },
];

export default new Enum(auditStatusList);
export { auditStatusList as AuditStatusList };
